import {
    Box,
    Container,
    FormControlLabel,
    Grid,
    MenuItem,
    Paper,
    Switch,
    TablePagination,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { getFeedback, updateArchivedStatus } from '../../../http-actions/feedback-actions';
import { AuthContext } from '../../../providers/auth-provider';
import FeedbackList from './feedback-list';
import FeedbackListSelect from './feedback-list-select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomButton from '../../button';

import store from '../../../store';
import { enqueueNotification as enqueueNotificationAction } from '../../../reducers/notifications-slice';
import FeedbackListFilter from './feedback-list-filter';

export interface Feedback {
    id: number;
    userId: number;
    location: string;
    feedback: Record<string, unknown>;
    createdAt: string;
    updatedAt: string;
    archived: boolean;
}

interface PaginationData {
    limit: number;
    offset: number;
    direction: 'ASC' | 'DESC';
    count: number;
}

const dispatch = store.dispatch;
const enqueueNotification = (payload: any) => dispatch(enqueueNotificationAction(payload));

export default () => {
    const { state: authState } = useContext(AuthContext);
    const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [paginationData, setPaginationData] = useState<PaginationData>({
        limit: 10,
        offset: 0,
        direction: 'DESC',
        count: -1,
    });

    const [showArchived, setShowArchived] = useState(false);
    const [showEmpty, setShowEmpty] = useState(false);
    const [locationFilter, setLocationFilter] = useState<string>('');

    const fetchFeedback = (
        offset: number,
        limit: number,
        direction: 'ASC' | 'DESC',
        showArchivedScoped: boolean,
        showEmptyScoped: boolean,
        locationFilter: string,
    ) => {
        if (authState?.token) {
            getFeedback(
                authState?.token,
                offset,
                limit,
                direction,
                showArchivedScoped,
                showEmptyScoped,
                locationFilter,
            ).then((response) => {
                if (!response.err) {
                    setFeedbacks(response.data);
                    setPaginationData(() => {
                        const newData = {
                            ...response.paginationData,
                        };
                        return newData;
                    });
                }
            });
        }
    };

    useEffect(() => {
        fetchFeedback(
            paginationData.offset,
            paginationData.limit,
            paginationData.direction,
            showArchived,
            showEmpty,
            locationFilter,
        );
    }, [authState?.token]);

    const resetSelectedItem = () => {
        setSelectedIndex(-1);
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        const offset = newPage * paginationData.limit;
        const limit = paginationData.limit;
        const direction = paginationData.direction;
        fetchFeedback(offset, limit, direction, showArchived, showEmpty, locationFilter);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const offset = 0;
        const limit = parseInt(event.target.value, 10);
        const direction = paginationData.direction;
        fetchFeedback(offset, limit, direction, showArchived, showEmpty, locationFilter);
    };

    const handleDirectionToggle = (
        _event: React.MouseEvent<HTMLElement>,
        newDirection: 'ASC' | 'DESC',
    ) => {
        const offset = paginationData.offset;
        const limit = paginationData.limit;
        fetchFeedback(offset, limit, newDirection, showArchived, showEmpty, locationFilter);
    };

    const handleSpecificPageSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value, 10);
        setSpecificPage(value);
    };

    const setSpecificPage = (value: number) => {
        const offset = value * paginationData.limit;
        const limit = paginationData.limit;
        const direction = paginationData.direction;
        fetchFeedback(offset, limit, direction, showArchived, showEmpty, locationFilter);
    };

    const handleArchiveFeedback = async (id: number, archived: boolean) => {
        if (authState?.token) {
            const response = await updateArchivedStatus(authState?.token, id, archived);

            console.log('QQ-res', response.data);

            if (!response.err) {
                setFeedbacks((prev) => {
                    return prev.map((feedback) => (feedback.id === id ? response.data : feedback));
                });

                handleNavigateToNext();

                const action = archived ? 'Archived' : 'Unarchived';
                enqueueNotification({
                    message: `${action} feedback with id = ${id}`,
                    options: {
                        key: `feedback-archived-${id}-${Date.now().toString()}`,
                        variant: 'success',
                        persist: false,
                    },
                });
            }
        }
    };

    const handleShowArchived = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowArchived(event.target.checked);
    };

    const handleShowEmpty = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowEmpty(event.target.checked);
    };

    useEffect(() => {
        const offset = paginationData.offset;
        const limit = paginationData.limit;
        const direction = paginationData.direction;
        fetchFeedback(offset, limit, direction, showArchived, showEmpty, locationFilter);
        resetSelectedItem();
    }, [showArchived, showEmpty, locationFilter]);

    const selectedFeedback =
        feedbacks && Array.isArray(feedbacks) ? feedbacks[selectedIndex] : undefined;

    const orderToggleControl = {
        value: paginationData.direction,
        onChange: handleDirectionToggle,
        exclusive: true,
    };

    const page = paginationData.offset / paginationData.limit;

    const pageCount =
        paginationData.count >= 0 ? Math.ceil(paginationData.count / paginationData.limit) : -1;
    const pageOptions = pageCount > 0 ? [...Array(pageCount).keys()] : [];

    const handleNavigateToNext = () => {
        console.log('PAGE COUNT COMP', page, pageCount, selectedIndex, paginationData.limit);
        if (selectedIndex < paginationData.limit - 1) {
            setSelectedIndex((prev) => prev + 1);
            return;
        }

        if (selectedIndex === paginationData.limit - 1 && page < pageCount - 1) {
            setSelectedIndex(0);
            setSpecificPage(page + 1);
        } else {
            setSelectedIndex(-1);
        }
    };

    const feedbackTypes =
        selectedFeedback &&
        Object.entries(selectedFeedback.feedback).reduce<{
            numeric: Record<string, number>;
            text: Record<string, string>;
        }>(
            (prev, [key, value]) => {
                const valueFloat = parseFloat(String(value));
                if (!Number.isNaN(valueFloat)) {
                    return { ...prev, numeric: { ...prev.numeric, [key]: valueFloat } };
                } else {
                    return { ...prev, text: { ...prev.text, [key]: String(value) } };
                }
            },
            { numeric: {}, text: {} },
        );

    console.log('QQ - page', page, pageCount, feedbackTypes);

    console.log('QQ', feedbacks);

    console.log('QQ', selectedFeedback, selectedIndex);

    console.log('QQ show', showArchived);

    return (
        <Container maxWidth={'xl'}>
            <Grid
                container
                sx={{
                    gridTemplateRows: { xs: '160px auto', md: '1fr 100px' },
                }}
            >
                <Grid
                    item
                    xs={12}
                    md
                    p={2}
                    sx={{
                        overflow: 'hidden',
                        display: 'grid',
                        gridTemplateRows: { xs: 'auto', md: '1fr 100px' },
                        height: { md: 'calc(100vh - 64px)' },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            overflow: 'hidden',
                        }}
                    >
                        <Paper
                            elevation={0}
                            sx={{
                                height: '100%',
                                width: '100%',
                                display: { xs: 'none', md: 'flex' },
                                flexDirection: 'column',
                                flexGrow: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    p: 2,
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    justifyContent: 'space-between',
                                    gap: 2,
                                    overflowX: 'auto',
                                    flexShrink: 0,
                                }}
                            >
                                <Typography variant="h2" component="h1">
                                    Feedbacks
                                </Typography>
                                <Box display="flex" gap="2" flexGrow="1" justifyContent="flex-end">
                                    <FormControlLabel
                                        checked={showArchived}
                                        control={
                                            <Switch color="fitech" onChange={handleShowArchived} />
                                        }
                                        label={
                                            <Typography variant="caption">Show archived</Typography>
                                        }
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        checked={showEmpty}
                                        control={
                                            <Switch color="fitech" onChange={handleShowEmpty} />
                                        }
                                        label={
                                            <Typography variant="caption">
                                                Show empty answers
                                            </Typography>
                                        }
                                        labelPlacement="start"
                                    />
                                </Box>
                            </Box>
                            <Box sx={{}}>
                                <FeedbackListFilter
                                    setLocationFilter={(loc) => setLocationFilter(loc)}
                                />
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                flexGrow={1}
                                px={2}
                                mb={2}
                                sx={{ overflowY: 'auto' }}
                            >
                                <FeedbackList
                                    feedbacks={feedbacks}
                                    selectedIndex={selectedIndex}
                                    setSelectedIndex={setSelectedIndex}
                                />
                            </Box>
                        </Paper>
                        <Paper
                            elevation={0}
                            sx={{
                                p: 2,
                                width: '100%',
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            <FeedbackListSelect
                                feedbacks={feedbacks}
                                selectedIndex={selectedIndex}
                                setSelectedIndex={setSelectedIndex}
                            />
                        </Paper>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            overflowX: 'auto',
                            flexWrap: 'nowrap',
                            width: '100%',
                        }}
                    >
                        <ToggleButtonGroup
                            size="small"
                            {...orderToggleControl}
                            aria-label="List order"
                        >
                            <ToggleButton value="ASC" key="ASC">
                                ASC
                            </ToggleButton>
                            <ToggleButton value="DESC" key="DESC">
                                DESC
                            </ToggleButton>
                        </ToggleButtonGroup>

                        <Box ml={2} width="64px" flexShrink={0}>
                            <TextField
                                id="select-page"
                                select
                                size="small"
                                label="Page"
                                value={pageOptions?.length > 0 ? page : ''}
                                onChange={handleSpecificPageSelectChange}
                            >
                                {pageOptions.map((option) => (
                                    <MenuItem key={`select-${option}`} value={option}>
                                        {option + 1}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>

                        <TablePagination
                            component="div"
                            sx={{ flexShrink: 0 }}
                            count={paginationData.count}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={paginationData.limit}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={7} p={2} sx={{ maxHeight: { md: 'calc(100vh - 64px)' } }}>
                    <Paper
                        elevation={0}
                        sx={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Container
                            disableGutters
                            sx={{
                                overflow: 'hidden',
                                display: 'grid',
                                gridTemplateRows: { md: '64px auto' },
                                height: '100%',
                                width: '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    p: 2,
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    justifyContent: 'space-between',
                                    gap: 2,
                                }}
                            >
                                <Typography variant="h2">
                                    {selectedFeedback
                                        ? `Feedback entry ${selectedFeedback?.id}`
                                        : 'Select feedback'}
                                </Typography>
                                {selectedFeedback && (
                                    <CustomButton
                                        kind="fitech"
                                        color={selectedFeedback?.archived ? 'secondary' : 'fitech'}
                                        variant="contained"
                                        onClick={() =>
                                            handleArchiveFeedback(
                                                selectedFeedback.id,
                                                !Boolean(selectedFeedback?.archived),
                                            )
                                        }
                                    >
                                        {selectedFeedback?.archived ? 'Unarchive' : 'Archive'}
                                    </CustomButton>
                                )}
                            </Box>
                            {selectedFeedback && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flexGrow: 1,
                                        padding: 2,
                                        overflow: 'auto',
                                    }}
                                >
                                    <Typography variant="subtitle2" gutterBottom>
                                        {selectedFeedback?.location}
                                    </Typography>
                                    <Grid container pt={2}>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexGrow: 1,
                                                mb: 2,
                                            }}
                                        >
                                            <TableContainer>
                                                <Table
                                                    sx={{ minWidth: 650 }}
                                                    aria-label="feedback metadata table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>ID</TableCell>
                                                            <TableCell align="right">
                                                                UserID
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                Created at
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                Updated at
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                Archived
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                {selectedFeedback.id}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {selectedFeedback.userId}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {new Date(
                                                                    selectedFeedback.createdAt,
                                                                ).toLocaleDateString()}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {new Date(
                                                                    selectedFeedback.updatedAt,
                                                                ).toLocaleDateString()}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {Boolean(selectedFeedback.archived)
                                                                    ? 'true'
                                                                    : 'false'}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexGrow: 1,
                                            }}
                                        >
                                            <Box py={4} width="100%">
                                                {feedbackTypes &&
                                                    Object.entries(feedbackTypes.text)
                                                        .sort((a, b) => {
                                                            if (
                                                                [
                                                                    'improvementSuggestions',
                                                                    'feedbackText',
                                                                ].includes(a[0])
                                                            ) {
                                                                return -1;
                                                            }
                                                            if (['feedbackType']) {
                                                                return 0;
                                                            }
                                                            return 1;
                                                        })
                                                        .map(([key, value]) => {
                                                            if (
                                                                [
                                                                    'improvementSuggestions',
                                                                    'feedbackText',
                                                                    'textContent',
                                                                ].includes(key)
                                                            ) {
                                                                return (
                                                                    <Box key={key}>
                                                                        <Typography variant="h3">
                                                                            {key}
                                                                        </Typography>
                                                                        <Typography paragraph>
                                                                            {value}
                                                                        </Typography>
                                                                    </Box>
                                                                );
                                                            }

                                                            return (
                                                                <Box key={key}>
                                                                    <Typography variant="h3">
                                                                        {key}
                                                                    </Typography>
                                                                    <div
                                                                        className="gatsby-highlight"
                                                                        data-language="language-text"
                                                                    >
                                                                        <pre className="language-text">
                                                                            <code className="language-text">
                                                                                {value?.length > 512
                                                                                    ? value.substring(
                                                                                          0,
                                                                                          512,
                                                                                      )
                                                                                    : value}
                                                                            </code>
                                                                        </pre>
                                                                    </div>
                                                                </Box>
                                                            );
                                                        })}
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                flexGrow: 1,
                                            }}
                                        >
                                            <Typography variant="h3" gutterBottom>
                                                Numeric values
                                            </Typography>
                                            <TableContainer>
                                                <Table
                                                    sx={{ minWidth: 650 }}
                                                    aria-label="feedback numeric data table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Key</TableCell>
                                                            <TableCell>Value</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {feedbackTypes &&
                                                            Object.entries(
                                                                feedbackTypes.numeric,
                                                            ).map(([key, value]) => {
                                                                return (
                                                                    <TableRow key={key}>
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                        >
                                                                            {key}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {value}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                        </Container>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};
