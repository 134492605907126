import React from 'react';
import { Box } from '@mui/material';
import FeedbackLayout from '../../components/feedback/view/feedback-layout';

export default () => {
    return (
        <Box width="100%" display="flex" flexDirection="column" alignItems="center">
            <FeedbackLayout />
        </Box>
    );
};
