import { Box, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { Feedback } from './feedback-layout';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

interface FeedbackListI {
    feedbacks: Feedback[] | undefined;
    selectedIndex: number;
    setSelectedIndex: (index: number) => void;
}

export default ({ feedbacks, selectedIndex, setSelectedIndex }: FeedbackListI) => {
    if (!feedbacks || feedbacks?.length === 0) {
        return (
            <Box width="100%" p={2}>
                <Typography>No feedback</Typography>
            </Box>
        );
    }

    return (
        <>
            <TableContainer sx={{ width: '100%' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell key="feedbacks-table-type">ID</TableCell>
                            <TableCell key="feedbacks-table-method" align="right">
                                Method
                            </TableCell>
                            <TableCell key="feedbacks-table-date" align="right">
                                Date
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {feedbacks.map((feedback, index) => {
                            return (
                                <TableRow
                                    hover
                                    selected={selectedIndex === index}
                                    onClick={() => {
                                        if (selectedIndex === index) {
                                            setSelectedIndex(-1);
                                            return;
                                        }
                                        setSelectedIndex(index);
                                    }}
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={`feedback-list-${feedback.id}`}
                                >
                                    <TableCell>{feedback.id}</TableCell>
                                    <TableCell align="right">
                                        {feedback.feedback?.feedbackMethod
                                            ? feedback.feedback?.feedbackMethod
                                            : 'Form / Other'}
                                    </TableCell>
                                    <TableCell align="right">
                                        {new Date(feedback?.updatedAt).toLocaleDateString()}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

// export default ({ feedbacks, selectedIndex, setSelectedIndex }: FeedbackListI) => {
//     if (!feedbacks || feedbacks?.length === 0) {
//         return (
//             <Box width="100%" p={2}>
//                 <Typography>No feedback</Typography>
//             </Box>
//         );
//     }

//     return (
//         <List dense sx={{ width: '100%' }}>
//             {feedbacks.map((feedback, index) => {
//                 return (
//                     <ListItem
//                         dense
//                         key={`feedback-list-${feedback.id}`}
//                         disablePadding
//                         disableGutters
//                     >
//                         <ListItemButton
//                             dense
//                             selected={selectedIndex === index}
//                             onClick={() => {
//                                 if (selectedIndex === index) {
//                                     setSelectedIndex(-1);
//                                     return;
//                                 }
//                                 setSelectedIndex(index);
//                             }}
//                         >
//                             <ListItemText
//                                 sx={{
//                                     color: (theme) =>
//                                         selectedIndex > -1 && selectedIndex !== index
//                                             ? theme.palette.grey[400]
//                                             : 'inherit',
//                                 }}
//                                 primary={
//                                     <Typography variant="subtitle1" sx={{ color: 'inherit' }}>
//                                         {`Feedback entry ${feedback.id}`}
//                                     </Typography>
//                                 }
//                                 secondary={
//                                     <Typography variant="subtitle2" sx={{ color: 'inherit' }}>
//                                         {new Date(feedback?.updatedAt).toLocaleDateString()}
//                                     </Typography>
//                                 }
//                             />
//                         </ListItemButton>
//                     </ListItem>
//                 );
//             })}
//         </List>
//     );
// };
