import { Box, FormControl, TextField } from '@mui/material';
import _ from 'lodash';
import React from 'react';

interface FeedbackLocationSelectPropsI {
    setLocationFilter: (location: string) => void;
}
export const FeedbackLocationSelect = (props: FeedbackLocationSelectPropsI) => {
    const { setLocationFilter } = props;

    return (
        <Box
            sx={(theme) => ({
                margin: theme.spacing(2),
                
            })}
        >
            <FormControl sx={{ width: '100%', zIndex: 3 }}>
                <TextField
                    fullWidth
                    helperText={'Filter by location'}
                    onChange={_.debounce((e) => setLocationFilter(e.target.value), 700)}
                />
            </FormControl>
        </Box>
    );
};

export default FeedbackLocationSelect;
