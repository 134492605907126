import * as React from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Box, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Feedback } from './feedback-layout';

const feedbacks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((value) => `Title ${value}`);

interface FeedbackListSelectI {
    feedbacks: Feedback[] | undefined;
    selectedIndex: number;
    setSelectedIndex: (index: number) => void;
}

export default function FeedbackListSelect({
    feedbacks,
    selectedIndex,
    setSelectedIndex,
}: FeedbackListSelectI) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (!feedbacks || feedbacks?.length === 0) {
        return (
            <Box width="100%" p={2}>
                <Typography>No feedback</Typography>
            </Box>
        );
    }

    return (
        <>
            <List component="nav" aria-label="Feedback selection">
                <ListItemButton
                    id="feedback-select"
                    aria-haspopup="listbox"
                    aria-controls="feedback-menu"
                    aria-label="show selected feedback"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickListItem}
                >
                    <ListItemText
                        primary={
                            selectedIndex === -1
                                ? 'Press to select feedback'
                                : `Feedback entry ${feedbacks[selectedIndex]?.id}`
                        }
                        secondary={
                            selectedIndex === -1
                                ? ''
                                : new Date(feedbacks[selectedIndex]?.updatedAt).toLocaleDateString()
                        }
                    />
                    <ListItemIcon>
                        <ExpandMoreIcon />
                    </ListItemIcon>
                </ListItemButton>
            </List>
            <Menu
                id="feedback-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'feedback-select',
                    role: 'listbox',
                }}
            >
                {feedbacks.map((feedback, index) => (
                    <MenuItem
                        sx={{ width: 'calc(100vw - 32px)' }}
                        key={`feedback-list-select-${feedback.id}`}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                    >
                        <ListItemText
                            primary={
                                <Typography variant="subtitle1" sx={{ color: 'inherit' }}>
                                    {`Feedback entry ${feedback?.id}`}
                                </Typography>
                            }
                            secondary={
                                <Typography variant="subtitle2" sx={{ color: 'inherit' }}>
                                    {new Date(feedback?.updatedAt).toLocaleDateString()}
                                </Typography>
                            }
                        />
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
